<template>
  <div class="app">

    <NavigationMain />

    <div class="area area__server">

      <h1 class="area__server__h1 alternate">
        <span class="area__server__icon"></span>
        <span class="orange">de</span>Borkee
      </h1>
      <h3 class="version">version {{ appVersion }}</h3>

    </div>

    <BeeHive />

    <div class="area area__bottom">
      <span>
        &copy; 2022 borkee.de <strong class="color-white">//</strong> made with <span class="heart">♥️</span> in
        Goldbach<br />
        <a class="opa" href="https://www.flaticon.com/de/kostenlose-icons/honig" title="honig Icons">Honig Icons
          erstellt von Freepik - Flaticon</a> &nbsp;|&nbsp;
        <a class="opa" href="https://www.flaticon.com/de/kostenlose-icons/biene" title="biene Icons">Biene Icons
          erstellt von Freepik - Flaticon</a>
      </span>
    </div>

  </div>
</template>

<script>
import BeeHive from "./components/BeeHive.vue";
import { version } from '../package'

export default {
  name: 'App',
  components: {
    BeeHive
  },
  setup() {
    const appVersion = version;

    return {
      appVersion,
    }
  },
  mounted() {
    document.title = "deBorkee";
  }
}
</script>

<style lang="scss">
@import 'assets/scss/app.scss';
</style>
